import { Component } from '@angular/core';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';

@Component({
  selector: 'eule-custom-tooltip',
  standalone: true,
  imports: [
    NgStyle,
    SafeHtmlPipe,
    NgIf,
    NgClass,
  ],
  templateUrl: './custom-tooltip.component.html',
  styleUrl: './custom-tooltip.component.scss',
})
export class CustomTooltipComponent {
  /**
   * The text content of the tooltip.
   * @type {string}
   */
  tooltip: string | null = null;

  /**
   * The position of the tooltip relative to the element.
   * @type {'bottom' | 'top'}
   */
  tooltipPosition: 'bottom' | 'top' = 'top';

  /**
   * The left position of the tooltip in pixels.
   * @type {number}
   */
  left: number = 0;

  /**
   * The top position of the tooltip in CSS units.
   * @type {string}
   */
  top: string = 'auto';

  /**
   * The bottom position of the tooltip in CSS units.
   * @type {string}
   */
  bottom: string = 'auto';

  /**
   * Indicates whether the tooltip content is HTML.
   * @type {boolean}
   */
  useHtml: boolean = false;

  /**
   * Fixed width of the tooltip.
   */
  fixedWidth?: string = 'auto';

  constructor() {
  }
}
